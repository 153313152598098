/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.login {
  /**
 * Grid Variables - CH
 */
  /**
 * Custom SASS Mixins - CH
 */
  /**
 * Grid Variables - CH
 */
  background-color: transparent;
}

.login label {
  background: transparent;
}

.login input, .login button {
  display: inline-block;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  color: #707070;
  font-weight: normal;
  padding: 8px 2%;
  clear: both;
}

.login input[type="submit"], .login input[type="reset"], .login input[type="button"], .login button {
  border: 1px solid #89C6CC;
  padding: 5px 20px;
}

.login input[type="submit"]:hover, .login input[type="reset"]:hover, .login input[type="button"]:hover, .login button:hover {
  background: #f7f7f7;
}

.login input::-moz-focus-inner, .login button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.login input[type="text"] {
  font-size: 13px;
}

.login input[type="text"], .login input[type="email"], .login input[type="password"], .login textarea, .login select {
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1);
  color: #707070;
  width: 100%;
}

.login select {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

.login table {
  width: 100%;
}

.login table td {
  padding: 5px;
}
